<template>
  <svg class="svg-icon _icon" aria-hidden="true">
    <use :xlink:href="symbolId"></use>
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    symbolId() {
      return `#icon-${this.name}`
    }
  }
}
</script>

<style lang="sass" scope>
.svg-icon
  width: 1em
  height: 1em
  vertical-align: -0.15em
  fill: currentColor
  overflow: hidden
</style>
