<template>
  <div class="page-container">
    <parallax-scene class="main-scene" :width="1920" :height="1080" ref="scene" :camera-enabled="false">
      <parallax-el name="floor" :z-index="0" :far="1" :width="2100" :height="410" :x="-69" :y="771" perspectiveB :texture="require('./textures/floor.png')"></parallax-el>
      <parallax-el name="ceiling" :z-index="0" :far="1" :width="1055" :height="476" :x="780" :y="-25" :texture="require('./textures/ceiling.png')"></parallax-el>
      <parallax-el name="vista" :z-index="1" :far="1" :width="903" :height="709" :x="498" :y="140" :texture="require('./textures/vista.png')"></parallax-el>
      <parallax-el name="wall_right" :z-index="2" :far="0.8" :width="886+100" :height="944+50" :x="1034" :y="0-25" perspectiveWR :texture="require('./textures/wall_right.png')">
        <a class="group-link" href="https://www.merckgroup.com/cn-zh" target="_blank"></a>
      </parallax-el>
      <parallax-el name="bathtub" :z-index="3" :far="0.66" :width="563" :height="226" :x="1022" :y="-50" :texture="require('./textures/bathtub.png')"></parallax-el>
      <parallax-el name="wall_left" :z-index="20" :far="0.6" :width="1010+100" :height="1080+25" :x="0-100" :y="0-25" perspectiveWL :texture="require('./textures/wall_left.png')"></parallax-el>
      <parallax-el name="adorn" :z-index="1" :far="0.96" :width="122" :height="122" :x="960" :y="716" :texture="require('./textures/adorn.png')"></parallax-el>

      <!-- 轮播图 -->
      <parallax-el name="focus" :z-index="6" :far="0.7" :x="1419" :y="475" perspectiveWL>
        <div class="focus-box">
          <div class="focus-content">
            <swiper class="focus-swiper" ref="focusSwiper" :options="focusSwiperOptions"
            :auto-update="true" :auto-destroy="true" @slide-change-transition-start="updateCurrentSwiperActiveIndex">
              <swiper-slide class="focus-swiper-item" v-for="item of currentProductList" :key="item.id" @click.native="onProductClick(item)">
                <div class="_title">{{item.name}}</div>
                <img class="_img" :src="item.coverImg">
              </swiper-slide>
              <template slot="pagination">
                <div class="focus-swiper-pagination"></div>
                <div class="focus-swiper-navigation _next"><svg-icon name="next"></svg-icon></div>
                <div class="focus-swiper-navigation _prev"><svg-icon name="next"></svg-icon></div>
              </template>
            </swiper>
          </div>
        </div>
      </parallax-el>

      <!-- 菜单 -->
      <parallax-el v-for="(item,index) of products" :key="item.id" :name="`menu${index+1}`"
      :z-index="7" :far="0.8 - index*0.02" :x="menusPos[index].x" :y="menusPos[index].y" perspectiveWR>
        <div class="menu-box" :class="`_menu${index+1}`" ref="menuBtns">
          <div class="_clickable" :class="{_active:currentMenuIndex===index}" @click="onMenuToggle(index,item)">
            <div class="_icon" :style="{backgroundImage:`url(${item.icon})`}"></div>
            <span>{{item.name}}</span>
          </div>
        </div>
      </parallax-el>

      <!-- 广告 -->
      <parallax-el name="ad" :z-index="10" :far="0.66" :x="1128" :y="790" perspectiveWR :transform="{scale:0.7,rotateY:30,rotate:4.6}">
        <div class="ad-box">
          <transition name="ad-fade">
            <div class="ad-img" :key="currentAdImg" :style="{backgroundImage:`url(${currentAdImg})`}"></div>
          </transition>
        </div>
      </parallax-el>

      <parallax-el name="woman" :z-index="10" :far="0.7" :x="1316" :y="654">
        <div class="_el-woman"></div>
      </parallax-el>

      <!-- 活动 -->
      <parallax-el name="board" :z-index="15" :far="0.8" :x="754" :y="528" :transform="{scale:0.38,rotateY:31, rotateZ:-1}">
        <home-board v-bind="board" @click.native.capture="openBoard" ref="board"></home-board>
        <div class="board-close-btn" @click="closeBoard" ref="boardCloseBtn">
          <svg-icon name="close"></svg-icon>
        </div>
      </parallax-el>

      <!-- 视频 -->
      <parallax-el name="video" :z-index="21" :far="0.5" :x="49" :y="144" perspectiveWL>
        <div class="video-box">
          <div class="video-content">
            <home-video v-bind="video"></home-video>
          </div>
        </div>
      </parallax-el>

      <!-- 会议 -->
      <parallax-el name="button" :z-index="22" :far="0.5" :x="448" :y="576" perspectiveWL>
        <div class="button-box">
          <div class="_button" @click="onSpeakerBtnClick">
            <span class="_label">会议大厅</span>
          </div>
          <router-link to="/my" class="_button">
            <span class="_label">我的会议</span>
          </router-link>
        </div>
      </parallax-el>

      <parallax-el name="man" :z-index="23" :far="0.5" :x="-20" :y="447">
        <div class="_el-man"></div>
      </parallax-el>

    </parallax-scene>

  </div>
</template>

<script>
import { gsap } from "gsap/all"

import ParallaxScene from '@/components/parallax/parallax-scene'
import ParallaxEl from '@/components/parallax/parallax-el'

import HomeVideo from './video'
import HomeBoard from './board'

import * as api from '@/api/home'

const usedAnimations = Symbol('_usedAnimations')
import indexApi from '@/api/index';

export default {
  components: {
    ParallaxScene, ParallaxEl,
    HomeVideo, HomeBoard,
  },
  data () {
    return {
      currentMenuIndex: 0,
      currentSwiperActiveIndex: 0,
      menusPos: [
        {x:1239, y:468 },
        {x:1366, y:394 },
        {x:1558, y:330 },
        {x:1713, y:394 },
      ],
      board:{},
      video:{},
      products:[
        // {
        //   id:1, icon: '/images/disease-dm.svg', name: '糖尿病',
        //   productList: [
        //     {id: 1, coverImg: '/images/p-0.png', name: '格华止®盐酸二甲双胍片', tagImg: '/images/t-1.png'},
        //     {id: 2, coverImg: '/images/p-1.png', name: '格华止®盐酸二甲双胍片', tagImg: '/images/t-2.png'},
        //   ]
        // }
      ],
      focusSwiperOptions: {
        loop: false,
        autoplay: {
          delay: 7000,
          disableOnInteraction:false,
        },
        pagination:{
          el: '.focus-swiper-pagination',
          modifierClass: 'focus-swiper-',
          bulletClass: '_bullet',
          bulletActiveClass: '_active',
          clickable: true
        },
        navigation: {
          nextEl: '.focus-swiper-navigation._next',
          prevEl: '.focus-swiper-navigation._prev',
          disabledClass: '_disabled'
        },
        mousewheel: true
      },
    }
  },
  computed:{
    currentProductList(){
      let {products,currentMenuIndex} = this
      return products[currentMenuIndex]?.productList
    },
    currentAdImg(){
      let {products,currentMenuIndex,currentSwiperActiveIndex} = this
      return products[currentMenuIndex]?.productList?.[currentSwiperActiveIndex]?.tagImg
    }
  },
  watch:{
    ['products.length'](){
      this._isMounted ? this.createMenuAnim() : this.$once('hook:mounted', ()=>this.createMenuAnim())
    },
    async currentProductList(){
      await this.$nextTick()
      this.updateCurrentSwiperActiveIndex()
    }
  },
  created(){
	  let that = this;
		// this.getUserInfo();
		this[usedAnimations] = new Set()
	
		const errorMsg=(type)=>(msg)=> this.$message.error(`${msg}`);

		api.getHomeData().then(data=>{
			this.video = data.liveInfo
			this.board = {
				list: {
					schedule: data.activityInfo,
					news: data.dynamicNewsInfo
				}
			}
		}, errorMsg())
		api.getHomeProducts().then(data=>this.products = data.slice(0,4), errorMsg())
		 
  },
  destroyed(){
    const usedAnims = this[usedAnimations]
    usedAnims && Array.from(usedAnims).map(t=>t.kill())
  },
  methods: {
    async createMenuAnim(){
      await this.$nextTick()

      const usedAnims = this[usedAnimations]

      usedAnims.delete(this._menuBtnsAnim)
      this._menuBtnsAnim?.kill()

      let {menuBtns} = this.$refs

      menuBtns && usedAnims.add(
        this._menuBtnsAnim =
        gsap.fromTo(menuBtns,
          { y: '-10%' },
          {
            duration: 2,
            y: '10%',
            ease: 'sine.inOut',
            stagger: {
              each: 0.4,
              repeat: -1, yoyo: true,
            },
        })
      )
    },
    onMenuToggle(index,itemObj){
		let that = this;
		console.log(itemObj,"itemObj");
		let button_params = {
			button: "首页领域展示",
			buttonContent: itemObj.name,
			dataId: itemObj.id,
			type: parseInt(6)
		}
		that.$point.buttonClickFn(button_params);
		that.currentMenuIndex = index;
    },
    updateCurrentSwiperActiveIndex(){
      this.currentSwiperActiveIndex = this.$refs?.focusSwiper?.$swiper?.realIndex
    },
    onProductClick(product){
      this.$router.push(`product?id=${product.id}`).catch(err=>{})
    },
    onSpeakerBtnClick(){
        this.$router.push({path:'/conference-hall'})
        //location.href = 'https://merckcmp.nequal.com/'
    },
    async closeBoard(){
      await this._boardOpenAnim.reverse('opened')
      this._boardOpenAnim.kill()
      this._boardOpenAnim = null
    },
    openBoard(event){
      if(this._boardOpenAnim) return false
      event.stopPropagation()

      const {scene,board,boardCloseBtn} = this.$refs
      if(!scene||!board) return false

      const boardEl = scene.getEl('board')
      if(!boardEl) return false

      const {layer,wrap,box} = boardEl.$refs
      const {closeBtn} = board.$refs
      const content = board.$el

      const usedAnims = this[usedAnimations] //这个Set储存当前页面使用的所有动画实例，便于页面销毁时释放，详见destroyed

      scene.lockEl('board')
      scene.stopCameraUpdate()
      scene.resetCameraPos()
      //动画开始前，锁定面板元素，停止摄像头更新，重置摄像头位置（即禁止鼠标移动产生的视差效果）

      const tl = gsap.timeline({ //创建打开面板的动画，动画由GSAP驱动，详见文档 https://greensock.com/docs/v3/GSAP
        paused: true,
        overwrite: true,
        onStart(){
          usedAnims.add(tl)
        },
        onReverseComplete(){ //关闭面板时（见closeBoard方法）会倒放这个动画，这个函数在倒放完成后执行
          usedAnims.delete(tl)
          scene.unlockEl('board')
          scene.updateScene()
          scene.startCameraUpdate()
        }
      })

      tl.to(wrap,{
        duration: 0.5,
        x: 1042,
        y: 140,
        ease: 'power1.in'
      },0)
      .to(box,{
        duration: 0.5,
        scale:0.6, rotateY:-20, rotateZ:0, ease: 'power1.out',
        onComplete(){
          board.preview = false
        },
        onReverseComplete(){
          board.preview = true
        },
      },0)
      .set(layer, {zIndex: 30})
      .to(wrap,{
        duration: 1,
        x: -40,
        y: 120,
        ease: 'power3.inOut'
      })
      .to(box,{
        duration: 1,
        scale:0.76, rotateY:0, rotateZ:0,
        ease: 'sine.out'
      },'<')
      .to(content,{
        duration: 0.8,
        width: 1300,
        ease: 'sine.out'
      },'-=0.5')
      .set(layer, {zIndex: 30})
      .addLabel('opened') //至此面板已完全打开，之后是关闭按钮的弹出动画，添加标签opened，关闭面板时动画从这里开始倒放
      .fromTo(boardCloseBtn,{
        display: 'block',
        transition: 'none',
        opacity: 0,
        scale: 0,
      },{
        duration: 1,
        opacity: 1,
        scale: 1,
        ease: 'elastic.out(1, 0.3)'
      },)
      .set(boardCloseBtn, {clearProps: 'transform,transition'})

      this._boardOpenAnim = tl.play()
    },
    perfectInfo(obj){
      let that = this;
      console.log(obj);
    },
		// 个人中心数据
		getUserInfo(params) {
			let that = this;
			indexApi.getUserInfo(params).then(
				res => {
					if (res && res.code === 1000) {
						that.$point.userIdentity(res,that,(data)=>{
							that.userInfo = data;
							that.currUserName = data.name;
							that.currAvatar = data.avatar;
							that.$store.commit('setUserName', data.name);
							that.$store.commit('setUserAvatar', data.avatar);
						});
						// if (res.data && res.data.state === "invalid"){
						// 	console.log("登录无效账号,信息:",res.data.userInfo);
						// 	that.$message.error("无效账号，请联系管理员");
						// 	clearInterval(that.$options.timer);
						// 	that.$options.timer = null;
						// 		setTimeout(function() {
						// 			localStorage.setItem('fakeLogin',true);
						// 			localStorage.setItem('token',"");
						// 			that.$router.push({ name: 'login', params: {needAppeal: true,data:res.data} });
						// 		}, 1);
						// } else {
						// 	if (res.data && res.data.state === "temporary" || res.data && res.data.state === "checked"){
						// 		if (res.data.isAppeal === 1 || res.data.isPrivacy === 1){
						// 			res.data.userInfo = { name: res.data.name, avatar: res.data.avatar };
						// 			res.data.token = localStorage.getItem('token');
						// 			// localStorage.setItem('token',"");
						// 			localStorage.setItem('fakeLogin',true);
						// 			clearInterval(that.$options.timer);
						// 			that.$options.timer = null;
						// 			setTimeout(function() {
						// 				that.$router.push({ name: 'login', params: { needAppeal: true, data: res.data } });
						// 			}, 1);
						// 			return;
						// 			// console.log('token========', localStorage.getItem('token'));
						// 		}
						// 	}
						// }
						// let data = res && res.data;
						// that.userInfo = data;
						// let tagInfo = data && data.tagInfo;
						// that.currProductList = tagInfo.productList;
						// that.currRealmList = tagInfo.realmList;
						// that.currTopicList = tagInfo.topicList;
						// that.getSelectTagList();
						// that.$store.commit('setUserName', data.name);
						// that.$store.commit('setUserAvatar', data.avatar);
						// if (res.data && res.data.state === 'invalid') {
						// 	res.data.userInfo = { name: res.data.name, avatar: res.data.avatar };
						// 	res.data.token = localStorage.getItem('token');
						// 	console.log('token========', localStorage.getItem('token'));
						// 	clearInterval(that.$options.timer);
						// 	that.$options.timer = null;
						// 	setTimeout(function() {
						// 		that.$router.push({ name: 'login', params: { needAppeal: true, data: res.data } });
						// 	}, 1);
						// 	return;
						// } else {
							// let data = res && res.data;
							// that.userInfo = data;
							// let tagInfo = data && data.tagInfo;
							// that.currProductList = tagInfo.productList;
							// that.currRealmList = tagInfo.realmList;
							// that.currTopicList = tagInfo.topicList;
							// that.getSelectTagList();
							// that.$store.commit('setUserName', data.name);
							// that.$store.commit('setUserAvatar', data.avatar);
						// 	// this.$message.success('发送成功')
						// }
					} else {
						that.$message.error(res.message);
					}
				},
				err => {
					that.$message.success((err && err.message) || '获取失败，请联系管理员！');
				}
			);
		},
  }
}
</script>

<style lang="sass" scoped>
  .page-container
    position: relative
    padding: 0
    max-height: 100%
    overflow: hidden

  =bgFill($url)
    background-image: url($url)
    background-size: 100% 100%

  =overlayBorder($url: null)
    &:before
      content: ''
      position: absolute
      top: 0
      left: 0
      z-index: 1
      width: 100%
      height: 100%
      background-size: 100% 100%
      pointer-events: none
      @if $url
        background-image: url($url)

  =maskBox
    pointer-events: none
    position: relative
  =maskContent($mask)
    pointer-events: auto
    position: absolute
    width: 100%
    height: 100%
    background-color: $colorPrimary
    background-clip: content-box
    mask: url($mask)

  .main-scene
    width: 100%
    user-select: none
    ._el-man
      +bgFill('./images/man.png')
      width: 234px
      height: 670px
    ._el-woman
      +bgFill('./images/woman.png')
      width: 87px
      height: 246px
  .ad-box
    width: 342px
    height: 96px
    >.ad-img
      position: absolute
      width: 100%
      height: 100%
      background-size: contain
      background-position: left
      background-repeat: no-repeat

  //我的会议
  .button-box
    width: 324px
    height: 124px
    +bgFill('./images/button.png')
    pointer-events: none
    display: flex
    padding: 18px 27px 56px 26px
    transform: translateZ(0)
    >._button
      pointer-events: auto
      flex: 1
      width: 100%
      padding: 0 14px
      white-space: nowrap
      display: flex
      align-items: center
      justify-content: center
      text-align: center
      font-size: 16px
      line-height: 16px
      letter-spacing: .04em
      color: #fff
      position: relative
      cursor: pointer
      >._label
        position: relative
        &:before
          content: ''
          position: absolute
          height: 2px
          width: 100%
          bottom: -4px
          background-color: rgba(255,255,255,.4)
          transform: scaleX(0)
          transform-origin: left
          +transition(transform, .3s)
      &:hover>._label:before
        transform: scaleX(1)
      &:not(:last-child):after
        position: absolute
        right: 0
        content: ''
        height: 12px
        width: 1px
        background-color: rgba(255,255,255,.4)


  //菜单
  .menu-box
    position: relative
    pointer-events: none
    +overlayBorder
    >._clickable
      pointer-events: auto
      overflow: hidden
      background-color: $colorPrimary
      color: #fff
      height: 100%
      clip-path: ellipse(50% 50% at 50% 50%)
      display: flex
      align-items: center
      justify-content: center
      font-size: 14px
      cursor: pointer
      >._icon
        margin-right: 2px
        width: 30px
        height: 30px
        background-size: contain
        background-repeat: no-repeat
        background-position: center
      +transition(background-color)
      &:hover
        background-color: $colorPrimaryH
      &._active
        background-color: $colorSecondary
    &._menu1
      width: 220px
      height: 84px
      padding: 9px 54px 18px 31px
      &:before
        background-image: url('./images/menu1.png')
    &._menu2
      width: 247px
      height: 91px
      padding: 13px 74px 24px 46px
      &:before
        background-image: url('./images/menu2.png')
    &._menu3
      width: 229px
      height: 89px
      padding: 10px 66px 24px 20px
      &:before
        background-image: url('./images/menu3.png')
    &._menu4
      width: 289px
      height: 102px
      padding: 8px 103px 27px 24px
      &:before
        background-image: url('./images/menu4.png')

  //视频
  .video-box
      width: 770px
      height: 463px
      +maskBox
      +overlayBorder('./images/video-border.png')
  .video-content
      +maskContent('./images/video-mask.svg')
      padding: 10px 30px 53px 17px

  //轮播图
  .focus-box
      width: 424px
      height: 302px
      +maskBox
      +overlayBorder('./images/focus-border.png')
  .focus-content
      padding: 10px 24px 40px 16px
      +maskContent('./images/focus-mask.svg')
      position: relative
  .focus-swiper
    width: 100%
    height: 100%
    background-image: url('./images/focus-swiper-bg.png')
    background-size: cover
    &-item
      display: flex
      flex-direction: column
      align-items: flex-start
      cursor: pointer
      >._title
        margin: 33px 0 19px 76px
        font-weight: 500
        font-size: 16px
        color: #fff
      >._img
        align-self: center
        max-width: 220px
        max-height: 125px
        width: auto
        height: auto

    &-pagination::v-deep
      position: absolute
      top: 210px
      left: 90px
      display: flex
      z-index: 2
      ._bullet
        margin-right: 8px
        width: 8px
        height: 8px
        border-radius: 50%
        background-color: rgba(255,255,255.4)
        +transition(background)
        &._active
          background-color: $colorSecondary

    &-navigation
      position: absolute
      z-index: 2
      font-size: 20px
      top: 50%
      height: 80px
      margin-top: -40px
      padding: 0 10px
      display: flex
      align-items: center
      color: #fff
      cursor: pointer
      +transition('transform,opacity',.3s)
      &:hover
        transform: scale(1.2)
      &._disabled
        opacity: 0
        pointer-events: none
      &._next
        right: 0
        padding-right: 46px
        transform-origin: left
      &._prev
        left: 0
        padding-left: 46px
        transform-origin: right
        >._icon
          transform: scaleX(-1)


  .board-close-btn
    display: none
    cursor: pointer
    position: absolute
    right: 0
    top: 450-55px
    z-index: 5
    width: 90px
    height: 90px
    border-radius: 50%
    background-color: $colorSecondary
    font-size: 40px
    text-align: center
    line-height: 90px
    box-sizing: content-box
    border: 10px solid #fff
    color: #fff
    box-shadow: 0 0 20px rgba(0,0,0,.2)
    opacity: 0
    +transition('background-color,transform')
    &:hover
      background-color: $colorSecondaryH
      transform: scale(1.05)

  .ad-fade
    &-enter-active, &-leave-active
      position: absolute
      will-change: opacity
      +transition(opacity, .4s)
    &-enter,&-leave-to
      opacity: 0

  .group-link
    position: absolute
    width: 320px
    height: 82px
    left: 513px
    bottom: 76px
    clip-path: ellipse(50% 50% at 50% 50%)
    transform: rotate(6deg)
</style>
