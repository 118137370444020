import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/";
import stroage from '@/utils/stroage.js'

Vue.use(VueRouter);

const routes = [{
		path: '/',
		redirect: '/login'
	},
	{
		path: "/home",
		name: "Home",
		component: Home,
		meta: {
			type: parseInt(6)
		}
	},
	// 我的页面
	{
		path: "/my",
		name: "my",
		component: () => import("@/views/my"),
		meta: {
			type: parseInt(9)
		}
	},
	// 典型病历
	{
		path: "/medical-records",
		name: 'medicalRecords',
		component: () => import("@/views/medical-records"),
		meta: {
			type: parseInt(4)
		}
	},

	// 临床视角详情
	{
		path: "/medical-records-detail/:id?",
		name: 'medicalRecordsDetail',
		component: () => import("@/views/medical-records/details"),
		meta: {
			type: parseInt(4)
		}
	},
	// 动态资讯
	{
		path: "/news",
		name: 'news',
		component: () => import("@/views/news"),
		meta: {
			type: parseInt(3)
		}
	},
	// 动态详情
	{
		path: "/news-details/:id?",
		name: 'newsDetails',
		component: () => import("@/views/news/details"),
		meta: {
			type: parseInt(3)
		}
	},
	// 搜索页面
	{
		path: "/search",
		name: 'search',
		component: () => import("@/views/search"),
		meta: {
			type: parseInt(10)
		}
	},
	// 产品信息页
	{
		path: "/product",
		name: 'product',
		component: () => import("@/views/product"),
		meta: {
			type: parseInt(1)
		}
	},
	// 产品详情
	{
		path: "/product-detail/:id?",
		name: 'productDetail',
		component: () => import("@/views/product/details.vue"),
		meta: {
			type: parseInt(5)
		}
	},
	// 产品动态
	// {
	//   path: "/product-detail-dynimic",
	//   component: () => import("@/views/product/details.vue")
	// },
	// 会议大厅
	{
		path: "/conference-hall",
		name: 'conferenceHall',
		component: () => import("@/views/conference-hall"),
		meta: {
			type: parseInt(7)
		}
	},
	// 模拟看诊
	{
		path: "/simulation-visit",
		name: 'simulationVisit',
		meta: {
			type: parseInt(8)
		},
		component: () => import("@/views/simulation-visit")
	},
	// 活动日历
	{
		path: "/activity-calendar",
		name: 'activityCalendar',
		component: () => import("@/views/activity_calendar"),
		meta: {
			type: parseInt(2)
		}
	},
	// 活动日历详情
	{
		path: "/activity-calendar-detail",
		name: 'activityCalendarDetail',
		component: () => import("@/views/activity_calendar/detail"),
		meta: {
			type: parseInt(2)
		}
	},
	// 登录
	{
		path: "/login",
		name: 'login',
		component: () => import("@/views/login")
	},
	// 注册
	{
		path: "/register",
		name: 'register',
		component: () => import("@/views/register/index")
	},
	// 选择兴趣标签
	{
		path: "/register-next",
		name: 'registerNext',
		component: () => import("@/views/register/next-step")
	},
  //全时授权
  {
    path: "/quanshi/auth",
    name: "Quanshi",
    component: () => import("@/views/auth/quanshi")
  },
  //第三方登录-微信
  {
    path: "/otherLogin/auth",
    name: "otherLogin",
    component: ()=> import("@/views/auth/otherLogin")
  },
  // 素材库列表
  {
	path: "/material",
    name: "material",
    component: ()=> import("@/views/material/material"),
	meta: {
		type: parseInt(7)
	}
  },
  // 会议详情
  {
	path: "/meetingDetail",
    name: "meetingDetail",
    component: ()=> import("@/views/meeting/detail"),
	meta: {
		type: parseInt(7)
	}
  },
  // 会议评价
  {
	path: "/meetingEval",
    name: "meetingEval",
    component: ()=> import("@/views/meeting/meeting-eval"),
	meta: {
		type: parseInt(7)
	}
  },
  // 会议计划与执行
  {
	path: "/meetingplan",
    name: "meetingplan",
    component: ()=> import("@/views/meeting/meeting-plan"),
	meta: {
		type: parseInt(7)
	}
  }
];
let token = stroage.getLocalItem('token');
const router = new VueRouter({
	mode: 'history',
	routes,
	linkActiveClass: '_active',
	linkExactActiveClass: '_active-exact',
});
// 权限认证
// router.beforeEach((to, from, next) => {
//   if (to.fullPath != '/login' && token) {
//       next();
//   } else if (!token && to.fullPath == "/login") {
//       next();
//   } else if (token && to.fullPath == "/home") {
//     next();
// }
// });
export default router;
