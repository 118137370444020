import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import indexApi from "@/api/index"

import '@/icons'
import './style/reset.scss'
import './style/common.scss'

import Meta from 'vue-meta'
Vue.use(Meta)
import VueAMap from 'vue-amap';
Vue.use(VueAMap);

VueAMap.initAMapApiLoader({
  //高德的key
  key: "cc644a48b701c256e9a827f068743fdd",
  // 插件集合
  plugin: [
    'AMap.Geocoder',
    'AMap.Autocomplete', // 输入提示插件
    'AMap.PlaceSearch', // POI搜索插件
    'AMap.Scale', // 右下角缩略图插件 比例尺
    'AMap.OverView', // 地图鹰眼插件
    'AMap.ToolBar', // 地图工具条
    'AMap.MapType', // 类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
    'AMap.PolyEditor', // 编辑 折线多，边形
    'AMap.CircleEditor', // 圆形编辑器插件
    'AMap.Geolocation' // 定位控件，用来获取和展示用户主机所在的经纬度位置
  ],
  v: "1.4.4",
  uiVersion: "1.0.11" // 版本号
});

if (DESGIN_REM_USE) require('./utils/rem')

//全局组件
import components from '@/components'
Object.keys(components).forEach(key => Vue.component(key, components[key]))

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)

// 埋点数据类
import BuryingPoint from "@/utils/burying_point"

Vue.prototype.$point = BuryingPoint;

import stroage from './utils/stroage'
// 工具类
import tools from './utils/tools'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)


Vue.config.productionTip = false
Vue.prototype.$stroage = stroage;
Vue.prototype.$tools = tools;
// 将cancel,挂载到vue原型上
Vue.prototype.$cancel = function(){
  // 获取缓存的 请求取消标识 数组，取消所有关联的请求
  let cancelArr = window.axiosCancel || [];
  cancelArr.forEach((ele, index) => {
      ele.cancel("取消了请求")  // 在失败函数中返回这里自定义的错误信息
      delete window.axiosCancel[index]
  })
}
// 过滤器
Vue.filter('attendTypeFilter', function(val) {
  let obj = {
    'NONE': '游客',
    'VIEWER': '听众',
    'LECTURER': '讲者',
    'OWNER': '创建者',
    'COLLABORATOR':'会议协作者'
  }
  return obj[val] || '--'
})
Vue.filter('memberTypeFilter', function(val) {
  let obj = {
    'online_participation': '线上参会',
    'offline_participation': '线下参会',
    'none': '线上线下结合会',
  }
  return obj[val] || '--'
})

import { message } from '@/utils/resetMessage';
// Vue.prototype.$message = message; //重写message提示框  一定要放在Vue.use(ElementUI)之后



Vue.mixin({
  beforeRouteEnter(to, from, next) {
    console.log(to, from, "这是页面获取数据", to.name, to.query.IgnoreSecurity);
    if (to.name == "otherLogin" || to.name == "Quanshi" || to.name == "newsDetails" || to.name == "medicalRecordsDetail" || to.name == "activityCalendarDetail" || to.name == "login" || to.name == "register" || to.name == 'registerNext' || to.query.hasOwnProperty("IgnoreSecurity") == true) {
      console.log(2222);
      next();
    } else {
      next(vm => {
        vm.$options.timer = null;
        let read_params = {
          dataId: parseInt(to.params.id) ? parseInt(to.params.id) : "",
          type: parseInt(to.meta.type),
          pageUrl: to.fullPath,
          accessSide: "pc",
        }
        indexApi.read(read_params).then(res => {
          console.log("开始计算数据", res);
          if (res.code === 1000 && res.success) {
            let readId = parseInt(res.data.readId);
            vm.$options.timer = setInterval(() => {
              let five_params = {
                readId,
                time: parseInt(5),
              }
              BuryingPoint.fiveUpTime(five_params);
            }, 5000)
          } else {
            console.log("统计页面阅读时间接口报错了", res);
          }
        })
      })
    }
  },
  beforeRouteUpdate(to, from, next) {
    console.log("原组件不同id");
    next();
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.$options.timer);
    this.$options.timer = null;
    next();
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

router.afterEach((to, from, next) => {
  setTimeout(() => {
    var _hmt = _hmt || [];
    (function () {
      document.getElementById('baidu_tj') && document.getElementById('baidu_tj').remove();
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?b92be764ae017accb7ee0c32dcb6deee";
      hm.id = "baidu_tj";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();
  }, 0);
});
