<template>
  <div class="__parallax-scene-wrap" ref="wrap">
    <div class="__parallax-scene-box" ref="box">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap/all"
import { throttle } from '@/utils'

export default {
  name: 'ParallaxScene',
  props: {
    width: {
      type: Number,
      default: 1920
    },
    height: {
      type: Number,
      default: 950
    },
    resistanceX:{
      type: Number,
      default: 0.1
    },
    resistanceY:{
      type: Number,
      default: 0.03
    },
    cameraDuration:{
      type: Number,
      default: 0.8
    },
    cameraEnabled:{
      type: Boolean,
      default: true
    },
    views:{
      type: Object,
      default:()=>{}
    }
  },
  watch:{
    cameraEnabled(enabled){
      if(enabled){
        this.startCameraUpdate()
      }else{
        this.stopCameraUpdate(true)
        this.resetCameraPos(true)
      }
    }
  },
  async mounted(){
    await this.$nextTick()
    this.init()
  },
  destroyed(){
    this._cameraTimeline?.kill()
    window.removeEventListener('resize', this.reCalcSize)
    document.removeEventListener('mousemove', this.onMouseMove)
  },
  methods: {
    init(){
      const vm = this

      this._parallaxSceneReady = true
      this._camera = {x: 0, y:0, z:0}
      this._lockedElements = new Set()

      let {wrap,box} = this.$refs
      let {width,height} = this.$props

      gsap.set(box, {
        perspective: this.width,
        height,width,
      })

      this.$children.forEach(this.elPropsInit)
      this.reCalcSize()
      if(this.cameraEnabled) this.startCameraUpdate()

      window.addEventListener('resize', this.reCalcSize)
      document.addEventListener('mousemove', this.onMouseMove)
    },
    elPropsInit(el){
      if(!el._parallaxElReady) return false

      let {wrap,box} = el.$refs
      let {width,height,texture,transform} = this.getElProps(el)

      gsap.set(wrap, {
        width, height,
        backgroundImage: texture ? `url(${texture})` : '',
        backgroundSize: '100% 100%'
      })
      gsap.set(box, { clearProps: 'transform' })
      transform && gsap.set(box, {
        transformOrigin: '0 0',
        ...transform
      })
    },
    reCalcSize: throttle(400, false, function(){
      if(!this._parallaxSceneReady) return false

      const {wrap,box} = this.$refs

      const getSceneProp = gsap.getProperty(wrap)
      this.realWidth = getSceneProp('width')
      this.adaptiveRate = this.realWidth / this.width
      this.realHeight = this.height * this.adaptiveRate

      gsap.set(box, {
        scale: this.adaptiveRate,
        transformOrigin: '0 0'
      })
      gsap.set(wrap, {
        height: this.realHeight
      })

      this.updateScene({}, 0)
    }),
    resetCameraPos(duration){
      this.updateScene({x:0,y:0,z:0}, duration)
    },
    updateCarmeraPos: throttle(100, false, function(){
      if(!this._cameraUpdate) return false
      this.updateScene.apply(this,arguments)
    }),
    updateScene(carmeraPos = {}, tweenDuration = this.cameraDuration, byMouse){
      if(!this._parallaxSceneReady) return false
      this._cameraTimeline?.kill()

      const {_camera,realWidth,resistanceX,resistanceY,_lockedElements} = this

      let {x: oldX, y: oldY, z: oldZ} = _camera
      let targetPos = {..._camera,...carmeraPos}
      let {x: newX, y: newY, z: newZ} = targetPos

      let tl = this._cameraTimeline = gsap.timeline({
        paused: true,
        onComplete(){
          _camera.x = newX
          _camera.y = newY
          _camera.z = newZ
        }
      })

      let distance = Math.sqrt(Math.pow(newX - oldX, 2) + Math.pow(newY-oldY, 2))

      let offsetX = (newX * realWidth * resistanceX)
      let offsetY = (newY * realWidth * resistanceY)

      for(let el of this.$children){
        if(!el._parallaxElReady) continue
        if(_lockedElements.has(el.$props.name)) continue

        let {layer,wrap,box} = el.$refs

        let duration = tweenDuration
        let onComplete = null

        if(!el._firstTweenFlag){
          duration = 0
          onComplete=()=>el._firstTweenFlag = true
        }

        let dProps = this.getElProps(el)
        let {zIndex,x,y,far,perspectiveW,perspectiveWR,perspectiveWL,perspectiveB} = dProps

        let toX = x + (offsetX * far)
        let toY = y + offsetY

        gsap.set(layer,{zIndex})
        tl.to(wrap,{ duration, x: toX, y: toY, onComplete},0)

        if(perspectiveW || (perspectiveWR && offsetX > 0) || (perspectiveWL && offsetX < 0)){
          tl.to(wrap,{ duration, rotateY: 0.08 * offsetX * far},0)
        }
        if(perspectiveB){
          tl.to(wrap,{
            duration,
            skewX: -0.08 * offsetX * far,
            transformOrigin: 'top center'
          },0)
        }
      }

      byMouse && distance <= 0 ? tl.seek('<') : tl.timeScale(1-distance).play()
    },
    startCameraUpdate(force){
      if(!force && !this.cameraEnabled) return false
      this._cameraUpdate = true
    },
    stopCameraUpdate(interrupt){
      this._cameraUpdate = false
      interrupt && this._cameraTimeline?.kill()
    },
    onMouseMove(event){
      if(!this._cameraUpdate) return false

      let {x,y} = event

      let {realWidth,realHeight} = this
      let cx = x - realWidth / 2
      let cy = y - realHeight / 2

      this.updateCarmeraPos({
        x: -cx / realWidth,
        y: -cy / realWidth,
      },this.cameraDuration, true)
    },
    onElUpdate(el){
      if(!this._parallaxSceneReady) return false
      this.elPropsInit(el)
      this.updateScene()
    },
    onElDestroyed(el){
      this._cameraTimeline?.kill(el.$refs)
    },
    getEl(elName){
      return this.$children.find(el=>el.$props.name===elName)
    },
    getElProps(el){
      const dProps = el.$props
      return {...dProps, ...this.currentView?.elements?.[dProps.name]?.props}
    },
    toggleView(to){
      const view = this.views[to]
      if(!view) throw `视图[${to}]未定义`

      this.currentView = view
      const {resetCamera,stopCamera,elements} = view

      stopCamera && this.stopCameraUpdate(stopCamera===1)
      resetCamera && this.resetCameraPos(resetCamera)

      for(let key in elements){
        const el = this.getEl(key)
        if(!el) continue
        const {layer,wrap,box} = el.$refs

        const elCfg = elements[key]

        let {zIndex,x,y,transform} = elCfg.props

        if(transform){
          gsap.set(box, { clearProps: 'transform' })
          gsap.set(box, {
            ...transform
          })
        }
      }

      this.updateScene()
    },
    lockEl(elName,unlock){
      if(!this._parallaxSceneReady) return false

      const {_lockedElements} = this
      const method = _lockedElements[unlock?'delete':'add'].bind(_lockedElements)

      if(elName instanceof Array){
        elName.forEach(method)
      }else{
        method(elName)
      }
    },
    unlockEl(elName){
      this.lockEl(elName,true)
    }
  }
}
</script>

<style lang="sass" scoped>
  .__parallax-scene-wrap
    position: relative
    width: 100%
    overflow: hidden
    transform: translateZ(0)

  .__parallax-scene-box
    position: absolute
    width: 100%
</style>
