import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})
export default new Vuex.Store({
  state: {
      userAvatar:'',//用户头像
      userName:'',
      userType:''
  },
  mutations: {
    /**
     * 用户头像
     */
    setUserAvatar(state, value){
        console.log('setUserAvatar========',value)
        state.userAvatar = value;
    },
    setUserName(state, value){
        console.log('setUserName========',value)
        state.userName = value;
    },
    setUserType(state,value){
      state.userType = value;
    }
  },
  actions: {
  },
  modules: {
  },
   
  plugins: [vuexLocal.plugin]
})
