String.prototype.Trim = function() {
	return this.replace(/(^\s*)|(\s*$)/g, "");
};
import indexApi from "@/api/index"
const tools = {
	// 通过 router参数 查找当前路由对象
	findUrlByRouterParmas: function(parmas = {
		name: ''
	}) {
		if (Object.prototype.toString.call(parmas.name) !== '[object String]') {
			console.error('router.name入参错误,请传入string类型,当前值为:    ', parmas.name);
			return false;
		}
		if (parmas.name.Trim() === '') {
			console.error('跳转name传值不能为空字符串');
			return false;
		}
		let routeData = this.$router.resolve(parmas);
		return routeData;
	},

	//打开新窗口
	windowOpenByRouterParmas: function(parmas = {
		name: ''
	}) {
		let routeData = tools.findUrlByRouterParmas.call(this, parmas);
		if (routeData && routeData.href) {
			console.log("统一清除老页面的定时器,再打开新的页面");
			clearInterval(this.$options.timer);
			this.$options.timer = null;
			window.open(routeData.href, '_blank');
		} else {
			console.error('router没有对应的href，当前值为:', routeData);
		}
	},
	//时间戳转化为日期
	formatDated(timeStr) {
		if (typeof(timeStr) == 'undefined') {
			return ''
		} else {
			let date = new Date(parseInt(timeStr))
			let y = date.getFullYear()
			let MM = date.getMonth() + 1
			MM = MM < 10 ? ('0' + MM) : MM
			let d = date.getDate()
			d = d < 10 ? ('0' + d) : d
			let h = date.getHours()
			h = h < 10 ? ('0' + h) : h
			let m = date.getMinutes()
			m = m < 10 ? ('0' + m) : m
			let s = date.getSeconds()
			s = s < 10 ? ('0' + s) : s
			return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s
		}
	},
	//日期转化为时间戳
	formatDate(dateStr) {
		let date = new Date(dateStr); // 有三种方式获取 // date.getTime(); // date.valueOf(); // Date.parse(date);
		return date.getTime();
	},
	getCurrentTime(startDate, appointmentStatus, cb) {
		let currentTime = new Date().getTime(); //当前时间
		let startTime = this.formatDate(startDate);
		if (currentTime > startTime) {
			return cb(2)
		} else {
			return cb(appointmentStatus);
		}
	},
	readyLoadDetail(vm){
		vm.$options.timer = null;
		let to = vm.$route;
		let read_params = {
		  dataId: parseInt(to.params.id) ? parseInt(to.params.id) : "",
		  type: parseInt(to.meta.type),
		  pageUrl: to.fullPath,
		  accessSide: "pc",
		}
		indexApi.read(read_params).then(res => {
		  console.log("开始计算数据", res);
		  if (res.code === 1000 && res.success) {
		    let readId = parseInt(res.data.readId);
		    vm.$options.timer = setInterval(() => {
		      let five_params = {
		        readId,
		        time: parseInt(5),
		      }
		      vm.$point.fiveUpTime(five_params);
		    }, 5000)
		  } else {
		    console.log("统计页面阅读时间接口报错了", res);
		  }
		})
	},
}

export default tools;
