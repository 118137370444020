

import indexApi from '@/api/index'
import stroage from "./stroage"
const buryingPoint = {
	buttonClickFn(params){ //按钮点击统计埋点
	params["accessSide"] = "pc";
	  indexApi.setButtonClick(params).then((res)=>{
	    console.log("按钮点击统计成功,数据:",res)
	  },(error)=>{
	    console.log("按钮点击统计失败，失败原因:",error);
	  })
	},
	download(params){
		params["accessSide"] = "pc";
		indexApi.statisDownload(params).then((res)=>{
		  console.log("用户下载统计成功,数据:",res)
		},(error)=>{
		  console.log("用户下载统计失败，失败原因:",error);
		})
	},
	fiveUpTime(params){
		params["accessSide"] = "pc";
		indexApi.updateReadTime(params).then((res)=>{
		  // console.log("用户浏览更新统计成功,数据:",res);
			if (!res.success){
				console.log("统计页面阅读时间接口报错了",res);
			}
		},(error)=>{
		  console.log("用户浏览更新统计失败，失败原因:",error);
		})
	},
	getQrUrl(params,cb){
		indexApi.getQrUrl(params).then((res)=>{
			if(res.code === 1000 && res.data.qr_url){
				// that.imgData = res.data.qr_url;
				return cb(res.data)
			} else {
				return cb({})
				// that.imgData = "";
				console.log("获取二维码接口失败");
			}
		})
	},
	getUserCenterAppeal(params,cb){
		indexApi.getUserCenterAppeal(params).then((res)=>{
			return cb(res)
			console.log(res,"通过用户ID获取申诉信息接口请求失败");
		})
	},
	userIdentity(res,that,callb){
		console.log("=================",res);
		if (res.success && res.code === 1000){
			let data = res && res.data;
			that.userInfo = data;
			that.currUserName = data.name;
			that.currAvatar = data.avatar;
			that.$store.commit('setUserName', data.name);
			that.$store.commit('setUserAvatar', data.avatar);
			// if (that.$route.name === "login"){
			if (that.$route.name === "login" || that.$route.name === ""){
				console.log(localStorage.getItem('token'), localStorage.getItem('fakeLogin') != true)
				let agreement_id = parseInt(that.$route.query.id);
				if (agreement_id && agreement_id >= 0) {
					return;
				} else {
					if (localStorage.getItem('token') && localStorage.getItem('fakeLogin') != true){
						console.log("已登录可以直接进入首页",localStorage.getItem('token'),localStorage.getItem('goSupplement'),that.$route)
						that.$router.push({ path: '/home' });
					}
				}
			} else {
				if (res.data.state == "invalid" || res.data.state == null || res.data.state == ""){
					clearInterval(that.$options.timer);
					that.$options.timer = null;
					localStorage.clear();
					setTimeout(function() {
						that.$router.push({ name: 'login', params: {needAppeal: true,data:res.data}});
					}, 100);
					return;
				} else if (res.data.state == "temporary" || res.data.state == "checked"){
					if(res.data.isAppeal === 1){
						res.data.userInfo = { name: res.data.name, avatar: res.data.avatar };
						res.data.token = localStorage.getItem('token');
						localStorage.setItem('fakeLogin',false);
						localStorage.setItem('reallyLogin',0);
						localStorage.setItem('goSupplement',1);
						console.log('token========', localStorage.getItem('token'));
						clearInterval(that.$options.timer);
						that.$options.timer = null;
						setTimeout(function() {
							that.$router.push({ name: 'login', params: { needAppeal: true, data: res.data } });
						}, 100);
						return;
					} else if (res.data.state == "checked" && res.data.isViewCme === 0){
						res.data.userInfo = { name: res.data.name, avatar: res.data.avatar };
						res.data.token = localStorage.getItem('token');
						clearInterval(that.$options.timer);
						that.$options.timer = null;
						localStorage.clear();
						setTimeout(function() {
							that.$router.push({ name: 'login', params: {needAppeal: true,data:res.data}});
						}, 100);
						return;
					}
				}
				if(res.data.isPrivacy === 1){
					res.data.userInfo = { name: res.data.name, avatar: res.data.avatar };
					res.data.token = localStorage.getItem('token');
					localStorage.setItem('fakeLogin',true);
					localStorage.setItem('goSupplement',1);
					console.log('token========', localStorage.getItem('token'));
					localStorage.setItem('token',"");
					clearInterval(that.$options.timer);
					that.$options.timer = null;
					setTimeout(function() {
						that.$router.push({ name: 'login', params: { needAppeal: true, data: res.data } });
					}, 100);
					return;
				}
			}
			callb?callb(res.data):'';

			// if (res.data && res.data.state === ""){
			// 	console.log("登录无效账号,信息:",res.data.userInfo);
			// 	clearInterval(that.$options.timer);
			// 	that.$options.timer = null;
			// 	that.$message.error("无效账号，请联系管理员");
			// 		setTimeout(function() {
			// 			localStorage.setItem('fakeLogin',true);
			// 			localStorage.setItem('token',"");
			// 			that.$router.push({ name: 'login', params: {needAppeal: true,data:res.data}});
			// 		}, 1);
			// 	return;
			// } else {
			// 	if (res.data && res.data.state === "temporary" || res.data && res.data.state === "checked"){
			// 		if (res.data.isAppeal === 1 || res.data.isPrivacy === 1){
			// 			res.data.userInfo = { name: res.data.name, avatar: res.data.avatar };
			// 			res.data.token = localStorage.getItem('token');
			// 			localStorage.setItem('fakeLogin',true);
			// 			console.log('token========', localStorage.getItem('token'));
			// 			localStorage.setItem('token',"");
			// 			clearInterval(that.$options.timer);
			// 			that.$options.timer = null;
			// 			setTimeout(function() {
			// 				that.$router.push({ name: 'login', params: { needAppeal: true, data: res.data } });
			// 			}, 1);
			// 			return;
			// 		}
			// 	}
			// 	let data = res && res.data;
			// 	that.userInfo = data;
			// 	that.currUserName = data.name;
			// 	that.currAvatar = data.avatar;
			// 	that.$store.commit('setUserName', data.name);
			// 	that.$store.commit('setUserAvatar', data.avatar);
			// 	console.log("已登录可以直接进入首页",localStorage.getItem('token'),!localStorage.getItem('fakeLogin'),that.$route)
			// 	if (that.$route.name === "login" || that.$route.name === ""){
			// 		console.log(localStorage.getItem('token'), localStorage.getItem('fakeLogin') != true)
			// 		if (localStorage.getItem('token') && localStorage.getItem('fakeLogin') != true){
			// 			that.$router.push({ path: '/home' });
			// 		}
			// 	}
			// }
		} else {
			that.$message.error(res.message);
		}
	},
}
export default buryingPoint
