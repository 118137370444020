<template>
  <div class="board-wrap" :class="{_preview: preview}">
    <div class="board-border"></div>
    <div class="board-mask">
      <div class="board-box">
        <div class="_bg">
          <svg-icon name="sticker-1" class="sticker-1"></svg-icon>
          <svg-icon name="sticker-2" class="sticker-2"></svg-icon>
          <svg-icon name="sticker-3" class="sticker-3"></svg-icon>
        </div>
        <div class="board-content">
          <div class="board-tabs">
            <div class="_item" v-for="(item,index) of tabs" :key="item.id" @click="currentTabIndex = index"
            :class="{_active:currentTabIndex===index}">{{item.label}}</div>
          </div>
          <div class="board-scroll" :class="{['_disable-scroll']: currentList.length < 3}">
            <transition name="list-fade">
              <div class="board-list-wrap" :key="currentTabId">
                <transition-group name="item-fade" tag="div" class="board-list" :duration="{leave: preview ? null : 0}">
                  <div class="board-item" v-for="item of currentList" :key="item.id" @click="onItemClick(currentTab,item)">
                    <div class="_title">{{item.title}}</div>
                    <div class="_time" v-if="item.releaseTime">
                      <svg-icon name="time"></svg-icon>
                      <span>{{item.releaseTime}}</span>
                    </div>
                    <div class="_tags" v-if="item.realmName || item.productName">
                      <span class="_item" v-if="item.realmName">{{item.realmName}}</span>
                      <span class="_item" v-if="item.productName">{{item.productName}}</span>
                    </div>
                    <div class="_tag" v-if="item.tag">{{item.tag}}</div>
                    <div class="_cover" v-if="item.id == 'schedule'" :style="{backgroundImage:`url(${item[currentTab.coverImgKey]})`}"></div>
                  </div>
                </transition-group>
                <router-link :to="currentTab.more" class="board-more">了解更多</router-link>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api/home'

export default {
  name: 'HomeBoard',
  props:{
    list:{
      type: Object
    }
  },
  data(){
    return {
      tabs: [
        {id: 'news', label: '动态资讯', more: '/news',  coverImgKey: 'titleImg', detailRouteName:'newsDetails'},
        {id: 'schedule', label: '活动日历', more: '/activity-calendar', coverImgKey: 'coverImg', detailRouteName:'activityCalendar'},
        
      ],
      currentTabIndex: 0,
      preview: true
    }
  },
  computed:{
    currentTabId(){
      let {tabs,currentTabIndex} = this
      return tabs[currentTabIndex]?.id
    },
    currentList(){
      let {list,currentTabId,preview} = this

      let clist = list?.[currentTabId]
      return clist?.slice(0, preview ? 1 : 2) || {}
    },
    currentTab(){
      let {tabs,currentTabIndex} = this
      return tabs?.[currentTabIndex] || {}
    }
  },
  methods:{
    onItemClick(currentTab,item){
		let that = this;
     console.log('currentTabcurrentTabcurrentTab',currentTab)
     if(currentTab.id == 'schedule'){ //活动日历
        // that.$router.push(`activity-calendar-detail?id=${item.id}`)
			that.$router.push({name:"activityCalendarDetail",params:{id:item.id},query:{id:item.id}});
     }else{
        that.$router.push(`news-details/${item.id}`)
     }
    //   that.$router.push({
    //     name:currentTab.detailRouteName,
    //     params:{id:item.id}
    //   }).catch(err=>{})
    }
  }
}
</script>

<style lang="sass" scoped>
  .board-wrap
    width: 775px
    height: 946px
    position: relative
    pointer-events: none
  .board-border
    position: absolute
    z-index: 1
    top: 0
    bottom: 0
    right: 371px
    left: 138px
    background-image: url('./images/board-border-c.png')
    background-repeat: repeat-x
    &:before,&:after
      content: ''
      position: absolute
      height: 100%
    &:before
      background-image: url('./images/board-border-r.png')
      width: 371px
      right: -371px
    &:after
      background-image: url('./images/board-border-l.png')
      width: 138px
      left: -138px
  .board-mask
    padding: 6px 27px 54px 16px
    pointer-events: auto
    position: absolute
    width: 100%
    height: 100%
    background-color: $colorThird
    background-clip: content-box
    mask-image: url('./images/board-mask.svg')
    mask-size: auto 100%
    mask-position: right
    mask-repeat: no-repeat
  .board-box
    position: relative
    width: 100%
    height: 100%
    overflow: hidden
    >._bg
      position: absolute
      width: 100%
      height: 100%
      overflow: hidden
      >svg
        position: absolute
      .sticker-1
        color: $colorSecondary
        font-size: 75px
        left: 52px
        top: 40px
      .sticker-2
        color: $colorPrimary
        font-size: 697px
        right: -212px
        bottom: 156px
      .sticker-3
        color: $colorSecondary
        font-size: 398px
        left: -50px
        bottom: -184px
  .board-content
    position: relative
    z-index: 1
    overflow: hidden
    height: 100%
    display: flex
    flex-direction: column
    padding: 140px 0 0 0
  .board-tabs
    margin-left: 70px
    margin-bottom: 40px
    display: flex
    align-items: center
    >._item
      margin-right: 50px
      font-size: 30px
      color: $colorPrimary
      cursor: pointer
      +transition('font,color')
      font-weight: 500
      line-height: 34px
      &._active
        font-size: 56px
        color: #fff

  .board-scroll
    width: 1110px
    flex: 1
    overflow: hidden
    &:not(._disable-scroll)
      mask: linear-gradient(to bottom, transparent, black 26px, black 27px, black 94%, transparent)
      overflow-y: auto
    position: relative
    &::-webkit-scrollbar
      width: 10px
      height: 10px
    &::-webkit-scrollbar-track
      background: rgba(255,255,255,.06)
    &::-webkit-scrollbar-thumb
      background: rgba(255,255,255,.2)
      border-radius: 8px
      &:hover
        background: rgba(255,255,255,.3)

  .board-list-wrap
    display: flex
    flex-direction: column
    min-height: 100%
    padding-top: 7px
  .board-list
    display: flex
    flex-wrap: wrap
    margin-right: -80px
    margin-left: 50px
  .board-item
    flex: 0 0 auto
    display: flex
    flex-direction: column
    align-items: flex-start
    padding: 20px
    margin-right: 40px
    margin-bottom: 40px
    width: 460px
    cursor: pointer
    border-radius: 20px
    background-color: rgba(255,255,255,.1)
    +transition(background-color)
    &:hover
      background-color: rgba(255,255,255,.2)
    >._title
      +ellipsis()
      font-size: 36px
      font-weight: 500
      color: $colorPrimary
      margin-bottom: 20px
    >._tag
      margin-bottom: 40px
      height: 40px
      line-height: 40px
      color: #fff
      font-size: 18px
      padding: 0 18px
      position: relative
      perspective: 40px
      &:before
        content: ''
        position: absolute
        z-index: -1
        left: 0
        right: 10px
        height: 100%
        background-color: $colorSecondary
        border-radius: 8px
        transform: rotateY(-4deg)
        transform-origin: left
    >._cover
      height: 236px
      width: 420px
      background-size: cover
      border-radius: 20px
    >._time
      font-size: 26px
      height: 30px
      margin-right: 20px
      color: #fff
      display: flex
      align-items: center
      >._icon
        font-size: 24px
        margin-right: 5px

    >._tags
      margin: 20px 0
      >._item
        display: inline-block
        background: rgba(255, 255, 255, 0.1)
        height: 32px
        line-height: 32px
        font-size: 26px
        padding: 0 10px
        margin-right: 10px
        border-radius: 2px
        color: #fff

  .list-fade
    &-enter-active, &-leave-active
      position: absolute
      will-change: opacity
      +transition(opacity, .4s)
    &-enter, &-leave-to
      opacity: 0

  .item-fade
    &-enter-active, &-leave-active
      will-change: opacity
      +transition(opacity, .4s)
    &-enter, &-leave-to
      opacity: 0

  .board-more
    display: inline-block
    cursor: pointer
    margin: auto 0 60px 70px
    font-size: 26px
    color: #fff
    padding-left: 40px
    position: relative
    &:before
      content: ''
      position: absolute
      left: 0
      top: -6px
      width: 50px
      height: 50px
      border-radius: 50%
      background-color: rgba(255,255,255,.2)
    &:after
      content: ''
      position: absolute
      height: 3px
      width: 10px
      top: 18px
      left: 22px
      background-color: #fff

  ._preview
    .board-content
      pointer-events: none
    .board-item
      background-color: transparent


</style>
