<template>
	<div id="app">
<!--    path != '/login' && path != '/register' && path != '/register-next' && path != '/quanshi/auth'-->
		<header v-if="toName != 'login' && toName != 'register' && toName != 'registerNext' && toName != 'Quanshi' && toName != 'otherLogin'">
			<div>
				<img v-if="$route.path == '/my'" src="./assets/logo-2.png" alt="" class="logo" @click="goHome($route)" />
				<img v-else src="./assets/logo.png" alt="" class="logo" @click="goHome($route)" />
				<nav>
					<div v-for="item in navLeft" :key="item.id" :class="['item', { active: item.path == $route.path }]" @click="navClick(item)">{{ item.name }}</div>
					<!-- <div :class="['item', {'active': false}]" @click="$router.push('/news')">动态资讯</div>
          <div class="item" @click="$router.push('/product')">产品信息</div>
          <div class="item" @click="$router.push('/medical-records')">
            临床视角
          </div>
          <div class="item" @click="$router.push('/simulation-visit')">
            模拟看诊
          </div>
          <div class="item" @click="$router.push('/activity-calendar')">
            活动日历
          </div> -->
				</nav>
			</div>
			<div>
				<div class="controls">
					<div class="item" v-for="(item, index) in navRight" :key="item.id">
						<div :class="['item-text', 'icon' + (index + 1)]" @click="goUrl(item)">{{ item.name }}</div>
					</div>
					<!-- <div class="item">
            <div class="item-text icon2">下载产品说明书</div>
          </div>
          <div class="item">
            <div class="item-text icon3">默克医学信息</div>
          </div> -->
				</div>
				<div class="btn user" @mouseover="() => (this.showUserNav = true)" @mouseleave="() => (this.showUserNav = false)">
					<i class="item-user-icon"></i>
					<img src="" alt="" class="btn-icon" />
					<div class="user-nav" v-show="showUserNav">
						<!--  -->
						<div class="user-info" @click="goMy($route)">
							<div class="user-avatar"><img :src="userAvatar || defaultImg" alt="" /></div>
							<div class="user-nick">{{ userName || '默小克' }}</div>
						</div>
						<div class="links">
							<div @click.stop="goCollect">我的收藏</div>
							<div @click.stop="meeting">我的会议</div>
						</div>
						<div class="quit-btn" @click.stop="quitLogin">退出</div>
					</div>
				</div>
				<div class="btn search" @mouseover="() => (this.showSearchContent = true)" @mouseleave="() => (this.showSearchContent = false)">
					<img src="" alt="" class="btn-icon" />
					<div :class="['searchBtn', 'search-container', showSearchContent ? 'active' : '']">
						<input type="text" @keyup.enter="inputSearch" v-model.trim="searchInfo" placeholder="请输入关键词" />
						<i class="search-icon" @click="search"></i>
					</div>
				</div>
			</div>
		</header>
		<router-view class="view-container" />
		<div class="footer" v-if="toName != 'login' && toName != 'register' && toName != 'registerNext' && toName != 'Quanshi' && toName != 'otherLogin'">
			<p class="item-footer-text">
				© 2020 默克集团. 版权所有.
				<a href="http://beian.miit.gov.cn/" target="_blank">沪ICP备18032235号</a>
			</p>
			<div class="item-footer-weChart">
				微信
				<div class="float-code">
					<img v-if="imgData && imgData.qr_url" :src="imgData.qr_url" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import indexApi from '@/api/index';
import { mapState } from 'vuex';
let callBackUrl;
let vm;
export default {
	data() {
		return {
			showUserNav: false,
			showSearchContent: false,
			navigationList: [],
			navLeft: [],
			navRight: [],
			searchInfo: '',
			path: '',
      toName: "",
			defaultImg: require('./views/my/images/avatar.png'),
			imgData: ''
		};
	},
	created() {
		let that = this;
    vm = this;
    console.log(this.$route)
		if (that.$route.name != null && that.$route.name != 'login' && that.$route.name != 'Quanshi' && that.$route.name != 'register' && that.$route.name != 'registerNext' && that.$route.name != 'otherLogin') {
			that.getNavigationList();
      if (localStorage.getItem('token')) {
        that.getUserInfo();
		that.getSessionKey();
      }
		}
	},
	watch: {
		$route(to, from) {
      console.log(`来的页面数据:`,from,this.$store.state.userType,this.$route.name);
      console.log(`去的页面数据:`,to);
			this.path = to.path;
      this.toName = to.name;

			let showNav = to.name != 'login' && to.name != 'register' && to.name != 'registerNext' && to.name != 'Quanshi' && to.name != 'otherLogin';
			console.log('是否显示导航=====', showNav);
				//   第三方药店员工有效身份只能查看会议
			if(showNav&&this.$store.state.userType === 3&&this.$route.name!='conferenceHall' && (to.name!='conferenceHall' || from.name!='conferenceHall')){
				// this.$router.replace('/conference-hall'); 
				 this.$message.error('其它板块暂时无法访问');
				 const {href} = this.$router.resolve({name: "conferenceHall"});
                  console.log("conferenceHall",href)
                  return location.replace(href);
			}
			if (showNav && this.navLeft.length == 0) {
				this.getNavigationList();
			}
      if (to.name == "Home" || to.name == "Quanshi" || to.name == "register" || to.name == "login"){
        callBackUrl = to.query.callBackUrl;
        let params;
        if (callBackUrl){ //全时
          params = {
            source: "quanshi",
            sourceId: "",
            redirectUrl: callBackUrl,
          }
        } else {
          params = {
            source: "cme_pc",
            sourceId: ""
          }
        }
        vm.$point.getQrUrl(params, res => {
          vm.imgData = res;
          vm.$stroage.setLocalItem('public_qr_url', res);
        });
      }
		}
	},
	computed: {
		...mapState(
			['userAvatar', 'userName'] //上传的新头像
		)
	},
	methods: {
		// 跳转首页
		goHome($route) {
			let that = this;
			if ($route.path === '/home') return;
			that.$router.push('/home');
		},
		goMy($route) {
			let that = this;
			if ($route.path === '/my') return;
			that.$router.push('/my');
		},
		//导航跳转
		navClick(item) {
			let that = this;
			if (item.path === that.$route.path) {
				return;
			}
			that.$router.push(item.path);
		},
		// 获取导航列表
		getNavigationList() {
			let params = {
				// phone: this.form.phone
			};
			indexApi.getNavigationList(params).then(
				res => {
					if (res && res.code === 1000) {
						let data = res && res.data;
						this.navigationList = data;

						let navLeft = this.navigationList.filter(item => {
							return item.position === 1;
						});

						this.navLeft = navLeft;

						let navRight = this.navigationList.filter(item => {
							return item.position === 2;
						});
						this.navRight = navRight;
					}
				},
				err => {
					this.$message.success((err && err.message) || '获取失败，请联系管理员！');
				}
			);
		},
		// 个人中心数据
		getUserInfo() {
			let that = this;
			let params = {type: 0};
			indexApi.getUserInfo(params).then(
				res => {
					console.log('调用了获取用户信息接口：', res.data);
					if (res && res.code === 1000) {
						console.log("res.data.isAppeal === 1;:",res.data.isPrivacy,res.data.state);
						that.$point.userIdentity(res,that,(data)=>{
							console.log(data,"data")
              // sessionStorage.setItem("qsUserInfo",data)
							that.userInfo = data;
              that.$store.commit('setUserName', data.name);
              that.$store.commit('setUserAvatar', data.avatar);
						});
					} else {
						that.$message.error(res.message);
					}
				},
				err => {}
			);
		},
        //对接e讲者获取session-key
		getSessionKey(){
			let that = this;
			indexApi.getSessionKey({}).then(
				res => {
					if (res.success && res.code === 1000) {
						// that.agreeMentObj = res.data;
						

					} 
				},
				err => {
				console.log(err);
				}
			);
		},
		// 跳转我的收藏
		goCollect() {
			this.$router.push({
				name: 'my',
				query: {
					type: 'collection'
				}
			});
		},

		// 跳转我的会议
		meeting() {
			this.$router.push({
				name: 'my',
				query: {
					type: 'meeting'
				}
			});
		},
		// 退出登录
		quitLogin() {
			let that = this;
			that.showUserNav = false;
			clearInterval(that.$options.timer);
			that.$options.timer = null;
			that.$stroage.setLocalItem('token', '');
			localStorage.setItem('fakeLogin',true);
			localStorage.setItem("loginEdNeedApple",false);
			setTimeout(function() {
				that.$router.push({ name: 'login' });
			}, 100);
		},

		search() {
			if (this.searchInfo) {
				this.$router.push({
					path: '/search',
					query: { keywords: this.searchInfo }
				});
			}
		},

		inputSearch(event) {
			if (event.keyCode == 13) {
				this.search();
			}
		},
		/**
		 * 右侧跳转
		 */
		goUrl(itemObj) {
			let that = this;
			console.log(itemObj, 'itemObj');
			let button_params = {
				button: itemObj.name,
				buttonContent: '',
				dataId: '',
				type: parseInt(6)
			};
			that.$point.buttonClickFn(button_params);
			clearInterval(this.$options.timer);
			this.$options.timer = null;
			window.open(itemObj.path);
		}
	}
};
</script>

<style lang="scss">
#app {
	position: relative;
	z-index: 2000;
	header {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 0.79rem;
		background: rgba(0, 0, 0, 0.1);
		border-bottom: 1px solid rgba(255, 255, 255, 0.4);
		z-index: 99;

		> div {
			display: flex;
			align-items: center;

			.logo {
				width: 1.53rem;
				height: 0.24rem;
				margin: 0 0.6rem 0 0.4rem;
				cursor: pointer;
			}

			nav {
				display: flex;
				justify-content: space-between;
				width: 5.98rem;

				.item {
					font-size: 0.16rem;
					color: white;
					cursor: pointer;
					padding: 0.29rem 0;
					width: 1.08rem;
					text-align: center;
					&.active {
						background: #eb3c96;
					}
				}
			}

			.controls {
				display: flex;
				justify-content: space-between;
				// width: 4.23rem;
				margin-right: 0.4rem;

				.item {
					display: flex;
					align-items: center;
					cursor: pointer;

					.item-icon {
						width: 0.14rem;
						height: 0.16rem;
						margin-right: 0.11rem;
					}

					.item-text {
						font-size: 0.14rem;
						color: white;
						margin-right: 0.4rem;
						&.icon1 {
							&::before {
								content: '';
								width: 0.16rem;
								height: 0.16rem;
								background: url('./assets/images/baogao.png') no-repeat center / 100% 100%;
								display: inline-block;
								vertical-align: middle;
								margin-top: -1px;
								margin-right: 0.1rem;
							}
						}
						&.icon2 {
							&::before {
								content: '';
								width: 0.16rem;
								height: 0.16rem;
								background: url('./assets/images/xiazai.png') no-repeat center / 100% 100%;
								display: inline-block;
								vertical-align: middle;
								margin-top: -1px;
								margin-right: 0.1rem;
							}
						}
						&.icon3 {
							&::before {
								content: '';
								width: 0.16rem;
								height: 0.16rem;
								background: url('./assets/images/yixue.png') no-repeat center / 100% 100%;
								display: inline-block;
								vertical-align: middle;
								margin-top: -1px;
								margin-right: 0.1rem;
							}
						}
					}
				}
			}

			.btn {
				display: flex;
				align-items: center;
				position: relative;
				min-width: 0.79rem;
				height: 0.75rem;
				border-left: 1px solid rgba(255, 255, 255, 0.4);

				.btn-icon {
					position: absolute;
					top: 50%;
					right: 0.31rem;
					transform: translateY(-50%);
				}

				&.user {
					position: relative;
					.item-user-icon {
						width: 0.3rem;
						height: 0.31rem;
						position: absolute;
						top: 0.24rem;
						left: 0.24rem;
						background: url('./assets/images/user.png') no-repeat center / 0.2rem 0.2rem;
						cursor: pointer;
						z-index: 199;
					}

					&:hover {
						// background: #503291;
					}

					.user-nav {
						position: absolute;
						top: -0.05rem;
						right: -0.26rem;
						width: 2.42rem;
						height: 3.78rem;
						padding: 0 0.2rem;
						// background: #000;
						background: url('./assets/images/bg-header.png') no-repeat center / 2.42rem 3.88rem;
						font-size: 0.16rem;
						color: white;
						z-index: 198;
						// text-align: center;

						.user-info {
							display: flex;
							align-items: center;
							height: 0.8rem;
							border-bottom: 1px solid rgba(255, 255, 255, 0.1);
							margin-top: 0.79rem;
							margin-left: 0.2rem;
							cursor: pointer;

							.user-avatar {
								display: flex;
								align-items: center;
								justify-content: center;
								width: 0.45rem;
								height: 0.45rem;
								margin-right: 0.1rem;
								border-radius: 50%;
								background: rgba(255, 255, 255, 0.1);
								overflow: hidden;
								img {
									width: 0.45rem;
									height: 0.45rem;
								}
							}
						}

						.links {
							margin: 0.15rem 0;
							margin-left: 0.34rem;

							div {
								height: 0.35rem;
								cursor: pointer;
								line-height: 0.35rem;
							}
						}

						.quit-btn {
							height: 0.75rem;
							border-top: 1px solid rgba(255, 255, 255, 0.1);
							line-height: 0.75rem;
							cursor: pointer;
							margin-left: 0.34rem;
						}
					}
				}

				&.search {
					position: relative;
					.search-icon {
						width: 0.2rem;
						height: 0.2rem;
						background: url('./assets/images/search.png') no-repeat center / 100% 100%;
						cursor: pointer;
						display: block;
						position: absolute;
						right: 0.3rem;
						top: 0.27rem;
					}

					.search-container {
						width: 0;
						margin: 0 0.2rem;
						border-radius: calc(0.45rem / 2);
						background: rgba(255, 255, 255, 0.2);
						transition: width 0.1s;
						overflow: hidden;

						&.active {
							width: 2.09rem;
							padding: 0 0.1rem;
						}

						input {
							width: 1.59rem;
							height: 0.45rem;
							border: none;
							background: none;
							color: white;
							font-size: 0.14rem;
							line-height: 0.45rem;

							&::placeholder {
								color: white;
								font-size: 0.14rem;
							}

							&:focus {
								border: none;
								outline: none;
							}
						}
					}
				}
			}
		}
	}

	.footer {
		height: 0.6rem;
		line-height: 0.6rem;
		// background: rgba(0, 0, 0, 0.1);
		// position: absolute;
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);

		.item-footer-text {
			float: left;
			margin-left: 0.39rem;
			font-size: 0.16rem;
			font-family: Noto Sans CJK SC;
			font-weight: 400;
			color: #ffffff;
			a {
				color: #ffffff;
			}
		}
		.item-footer-weChart {
			float: right;
			font-size: 0.16rem;
			font-family: Noto Sans CJK SC;
			font-weight: 400;
			color: #ffffff;
			margin-right: 0.39rem;
			cursor: pointer;
			&::before {
				width: 0.24rem;
				height: 0.24rem;
				content: '';
				background: url('./assets/images/wechat.png') no-repeat center / 100% 100%;
				display: inline-block;
				margin-right: 0.08rem;
				vertical-align: middle;
			}
			.float-code {
				width: 1.5rem;
				height: 1.5rem;
				position: absolute;
				z-index: 99;
				right: 0.15rem;
				bottom: 0.7rem;
				display: none;
			}
			&:hover {
				.float-code {
					display: block;
				}
			}
		}
	}
}

.view-container {
	height: 100%;
	overflow: hidden;
	padding: 0.8rem 0 0.6rem 0; //降低优先级，建议避免使用!important
}

.quanshiDialog{
  background: transparent !important;
  box-shadow: none !important;
}
</style>
