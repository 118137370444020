console.log(process.env, '===========>process.env')
let host,mettinghost;
if (process.env.VUE_APP_URL === "dist") {
  host = 'http://cmeonline.merckgroup.com.cn/service';
} else if (process.env.VUE_APP_URL === "test") {
  host = "http://cme.kydev.net/service";
  mettinghost = "https://eplus.kydev.net/"
} else {
  host = "/cme";
  mettinghost = '/epl'
}
console.log({baseUrl: host, hostUrl: host,},"接口请求的host:",host);
export default {
  baseUrl: host,
  hostUrl: host,
  mettingUrl:mettinghost
}
