import {throttle} from '@/utils'

let elHTML = document.documentElement

const setRootFontSize = throttle(400, false, function(){
  let viewWidth = Math.max(elHTML.clientWidth, window.innerWidth || 0)

  viewWidth = Math.max( Math.min(viewWidth, DESGIN_REM_MAX_WIDTH || Infinity), DESGIN_REM_MIN_WIDTH || 0 )

  let rootFontSize = window.__rootFontSize = viewWidth / DESGIN_WIDTH * DESGIN_REM_RATE
  elHTML.style.fontSize = rootFontSize + 'px'
})

setRootFontSize()

window.addEventListener('resize', event=>setRootFontSize(), false)
