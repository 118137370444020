<template>
  <div class="__parallax-el-layer" ref="layer">
    <div class="__parallax-el-wrap" ref="wrap">
      <div class="__parallax-el-box" ref="box">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ParallaxEl',
  props: {
    name: String,
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    zIndex: {
      type: Number,
      default: 0
    },
    far: {
      type: Number,
      default: 0.1
    },
    transform: {
      type: Object
    },
    texture: String,
    width: Number,
    height: Number,
    perspectiveB: Boolean,
    perspectiveW: Boolean,
    perspectiveWR: Boolean,
    perspectiveWL: Boolean
  },
  watch:{
    // $props: {
    //   handler(nv){
    //     this.$parent?.onElUpdate(this)
    //   },
    //   deep: true
    // }
  },
  mounted(){
    this._parallaxElReady = true
    this.$parent?.onElUpdate(this)
  },
  destroyed(){
    this.$parent?.onElDestroyed(this)
  }
}
</script>

<style lang="sass" scoped>
  .__parallax-el-layer
    position: absolute
    -webkit-transform: translate3d(0,0,0) //safari z-index bug fix
    perspective: inherit
    top: 0
    left: 0
    width: 100%
    height: 100%
    pointer-events: none
  .__parallax-el-wrap
    position: absolute
    perspective: inherit
  .__parallax-el-box
    pointer-events: auto
</style>
