<template>
  <div class="home-video" @click="getLivePath(id,status)">
    <div class="_cover" :style="coverStyle"></div>
    <!-- <a class="_link" :href="path" v-if="path" target="_blank"></a> -->
    <div class="_overlay">
      <div class="_row">
        <div class="_waveform"></div>
        <div class="_play">
          <svg-icon name="play"></svg-icon>
        </div>
        <div class="_waveform"></div>
      </div>
      <div class="_title">{{title || '欢迎来到CME在线'}}</div>
      <div class="_status" v-if="status != -1">{{statusText}}</div>
      <div class="_tip" v-if="attendType">
         <span>角色：{{attendType | attendTypeFilter}}</span>
          <span >会议形式：{{memberSigninType | memberTypeFilter}}</span>
      </div>
    </div>
      <!-- 会议链接弹框 -->
    <!-- <MeetModel v-if="onlineDialogVisible" :meetingInfo="meetingInfo" :visible.sync='onlineDialogVisible' @close='onlineDialogVisible = false' /> -->
  </div>
</template>

<script>
import indexApi from '@/api/index'
// import MeetModel from '@/components/site/model'
const statusMap = {
  [-1]: '已结束',
  [1]: '待开始',
  [0]: '直播中'
}

export default {
  // components: {MeetModel},
  name: 'HomeVideo',
  props: {
    title: String,
    coverImg: {
      type: String,
      default: require('./images/video-cover.png')
    },
    id:Number,
    status:{
      type: Number
    },
    path:{
      type: String
    },
    meetingPattern:{
      type: String
    },
    memberSigninType:{
      type: String
    },
    attendType:{
      type: String
    },
    meetingInfo:{
      type:Object,
      default: function() {
				return {};
			}
    }
  },
  data(){
     return{
      //  onlineDialogVisible:false,
      
     }
  },
  computed:{
    coverStyle(){
      return {
        backgroundImage: `url(${this.coverImg})`
      }
    },
    statusText(){
      return statusMap[this.status]
    },
  },
  methods:{
    getLivePath(id) {
		let that = this;
		let params = {
		  meetingId: id
		};
    
    if(this.meetingPattern == 'offline' || 
      (this.meetingPattern == 'online_and_offline'&&this.memberSigninType =='offline_participation')
      ){
          this.$message.error('当前线下会议，请您线下参会');
    }else{
       let button_params = {
        button: "正在直播的会议",
        buttonContent: "",
        dataId: id,
        type: parseInt(6)
      }
      that.$point.buttonClickFn(button_params)
        indexApi.getLivePath(params).then((res)=>{
        if(res && res.code === 1000){
          let data = res && res.data;
          clearInterval(this.$options.timer);
          this.$options.timer = null;
          window.open(data.url)
        }
        },(err)=>{})
      }
    }
		
  }
}
</script>

<style lang="sass" scoped>
  =overlay
    position: absolute
    width: 100%
    height: 100%
  .home-video
    position: relative
    width: 100%
    height: 400px
    >._cover
      +overlay
      background-size: cover
      background-position: center
    >._link
      position: absolute
      z-index: 3
      cursor: pointer
      width: 100%
      height: 100%
    >._overlay
      +overlay
      z-index: 2
      position: relative
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      cursor: pointer
      ._row
        display: flex
        align-items: center
      ._play
        margin: 0 20px
        width: 70px
        height: 70px
        line-height: 70px
        text-align: center
        font-size: 40px
        border-radius: 50%
        color: #fff
        background: $colorSecondary
        +transition(background-color)
        cursor: pointer
        &:hover
          background: $colorSecondaryH
        ._icon
          margin-left: 5px
      ._waveform
        width: 40px
        height: 24px
        background-image: url('./images/waveform.png')
        background-repeat: repeat-x
        animation: waveform 1.4s steps(5) infinite
      ._title
        margin-top: 24px
        color: #fff
        font-size: 24px
        font-weight: bold
        max-width: 100%
        padding: 0 80px
        text-align: center
        +ellipsis(2,1.4)
      ._status
        margin-top: 14px
        height: 30px
        line-height: 28px
        border-radius: 15px
        padding: 0 24px
        background-color: $colorThird
        color: #fff
        font-size: 16px
        border: 1px solid #fff
      ._tip
        position: absolute
        right: 38px
        top: 5px
        width: 2.8rem
        height: .69rem
        color: #fff
        font-size: 14px
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        &::after
          content: ''
          position: absolute
          right: 0
          top: 0
          display: block
          width: 2.8rem
          height: .69rem
          transform: rotate(180deg)
          background: url("~./images/more-bg.png") center center / 100% 100%
          z-index: -1
          
         
        
        
  @-webkit-keyframes waveform
    from
      background-position: 0 0
    to
      background-position: -40px 0

</style>
