export default {
    /**
     * 封装setLocalItem方法
     * @param key
     * @param data
     * @returns {}
     */
    setLocalItem(key, data) {
        if (typeof data === "object") {
            localStorage.setItem(key, JSON.stringify(data));
        } else {
            localStorage.setItem(key, data);
        }
    },

    /**
     * 封装getLocalItem方法
     * @param key
     * @param data
     * @returns {string | object}
     */
    getLocalItem(key, data) {
        return localStorage.getItem(key);
    },

    /**
     * 封装removeLocalItem方法
     * @param key
     * @param data
     * @returns {}
     */
    removeLocalItem(key, data) {
        localStorage.removeItem(key);
    }
};
