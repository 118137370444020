import {
	get,
	post
} from '@/utils/request'

export default {
	// 注册下拉框
	getSelectData(params) {
		return post(
			'/getSelectData',
			params
		)
	},

	// 注册
	register(params) {
		return post(
			'/register',
			params
		)
	},

	// 发送验证码
	sendSmsCode(params) {
		return post(
			'/sendSmsCode',
			params
		)
	},

	// 登录
	login(params) {
		return post(
			'/login',
			params
		)
	},

	// 个人中心
	getUserInfo(params) {
		return get(
			'/getUserInfo',
			params
		)
	},

	// 活动日历列表
	getActivityList(params) {
		return post(
			'/activity/list',
			params
		)
	},

	// 活动日历详情 /activity/detail/{id}
	getActivityDetail(params, url, headers) {
		return get(
			url,
			params,
			headers
		)
	},

	//活动日历预约
	signActivity(params){
		return get(
			'/activity/signUp/' + params.id,
		)
	},

	// 动态资讯分类
	dynamicTypeList(params) {
		return get(
			'/dynamicType/list',
			params
		)
	},

	// 获取动态咨询列表
	getDynamicNews(params) {
		return post(
			'/dynamicNews/list',
			params
		)
	},

	// 咨询详情 /dynamicNews/detail/
	getDynamicNewsDetail(params, url) {
		return get(
			url,
			params
		)
	},

	// 动态推荐阅读
	getRecommendList(params) {
		return post(
			'/dynamicNews/recommendList',
			params
		)
	},
	// 产品列表
	getProductList(params) {
		return get(
			'/product/list',
			params
		)
	},

	// 产品列表(动态资讯选择)
	getAllProductList(params) {
		return get(
			'/product/selectProductList',
			params
		)
	},

	// 产品动态列表
	getProductDynamicList(params) {
		return post(
			'/productDynamic/list',
			params
		)
	},

	// 产品详情
	getProductDynamicDetail(params, url) {
		return get(
			url,
			params
		)
	},

	// 临床视角
	getPerspectiveList(params) {
		return post(
			'/perspective/list',
			params
		)
	},

	// 模拟看诊
	getSimulationVisitList(params) {
		return post(
			'/simulationVisit/list',
			params
		)
	},

	// 活动月份列表
	getActivityMonthList(params) {
		return get(
			'/activity/monthList',
			params
		)
	},

	// 获取导航列表
	getNavigationList(params) {
		return get(
			'/navigation/list',
			params
		)
	},

	// 标签列表
	getSelectTagList(params) {
		return get(
			'/selectTagInfo',
			params
		)
	},

	// 保存标签
	saveTag(params) {
		return post(
			'/saveTag',
			params
		)
	},


	// 首页
	getHomeData(params) {
		return get(
			'/index/indexInfo',
			params
		)
	},

	// 用户收藏-取消收藏 /follow
	followOrCanFollow(params) {
		params["accessSide"] = "pc";
		return post(
			'/follow',
			params
		)
	},

	// 会议大厅
	getLiveList(params) {
		return get(
			'/live/list',
			params
		)
	},

	// 会议大厅跳转地址
	getLivePath(params) {
		return get(
			'/live/meetingPlay',
			params
		)
	},


	// 会议回放
	getPlaybackList(params) {
		return post(
			'/live/playbackList',
			params
		)
	},

	// 会议搜索
	getSearchList(params) {
		return post(
			'/live/searchList',
			params
		)
	},

	// 用户点赞
	setUserLikes(params) {
		params["accessSide"] = "pc";
		return post(
			'/likes',
			params
		)
	},

	// 产品收藏
	getUserFollowList(params) {
		return post(
			'/product/userFollowList',
			params
		)
	},

	// 活动收藏
	getActivityFollowList(params) {
		return post(
			'/activity/userFollowList',
			params
		)
	},

	// 资讯收藏
	getDynamicNewsFollowList(params) {
		return post(
			'/dynamicNews/userFollowList',
			params
		)
	},

	// 用户收藏产品动态列表
	getProductDynamicFollowList(params) {
		return post(
			'/productDynamic/userFollowList',
			params
		)
	},

	// 收藏-临床视角列表
	getPerspectiveFollowList(params) {
		return post(
			'/perspective/userFollowList',
			params
		)
	},

	// 我的会议
	getLiveMyList(params) {
		return post(
			'/live/myList',
			params
		)
	},

	// 产品搜索列表
	getProductSearchList(params) {
		return post(
			'/product/searchList',
			params
		)
	},

	// 产品详情 /product/detail/{id}
	getProductDetail(params, url) {
		return get(
			url,
			params
		)
	},
	//资讯详情-推荐阅读接口
	newsRecommendLis(params) {
		return post(
			'/dynamicNews/productRecommendList',
			params
		)
	},
	//产品动态
	productRecommendLis(params) {
		return post(
			'/productDynamic/recommendList',
			params
		)
	},


	// 修改头像
	updateAvatar(params) {
		console.log(params)
		let headers = {
			"Content-Type": 'multipart/form-data'
		}
		return post(
			'/user/updateAvatar',
			params,
			headers,
		)
	},

	//医院模糊查询
	getHospital(params){
		return post(
			'/hospital',
			params,
		)
	},

	//获取省市列表
	getCityList(params) {
		return get(
			'/area',
			params,
		)
	},

	//获取科室接口
	getDepartment(params){
		return get(
			'/department',
			params,
		)
	},

	//设置button按钮埋点
	setButtonClick(params) {
		return post(
			'/buttonClick',
			params,
		)
	},

	//统计用户下载记录
	statisDownload(params) {
		return post(
			'/download',
			params,
		)
	},

	//统计用户浏览记录
	read(params) {
		return post(
			'/read',
			params,
		)
	},

	//统计用户更新浏览记录浏览时间
	updateReadTime(params) {
		return post(
			'/updateReadTime',
			params,
		)
	},

	//获取最新知情同意书数据
	getPrivacyAgreement(params){
		return get(
			'/privacyAgreement/getPrivacyAgreement',
			params,
		)
	},

	//通过id去获取知情同意书
	getPrivacyAgreementId(params){
		return get(
			'/privacyAgreement/getById/' + params.id,
		)
	},

	//用户同意更新隐私协议
	getAgreementChoose(params){
		return post(
			'/privacyAgreement/agreementChoose',
			params,
		)
	},

	//用户完善信息接口
	doctorAppeal(params){
		let headers = {
			"Content-Type": "multipart/form-data",
		}
		return post(
			'/doctorAppeal',
			params,
			headers,
		)
	},

	//获取公众号注册扫码二维码
	getQrUrl(params){
		params["source"] = (params["source"] && params["source"] != '') ? params["source"] : "cme_pc";
		return post(
			'/qr_url',
			params,
		)
	},

	//获取用户是否关注公众号
	getIsSubscribe(params){
		return post(
			'/wechatUser/getIsSubscribe',
			params,
		)
	},

	//根据用户ID去获取用户申诉信息
	getUserCenterAppeal(params){
		return post(
			'/getAppealUserInfo',
			params,
		)
	},

  // 动态推荐阅读
  getQuanshiUserInfo(params) {
    return post(
      '/quanshi/getUserData',
      params
    )
  },
  
  // 会议相关 session-key 6377e1601fd8428c99f325a221d72d32
  // e讲者对接获取session-key 
  getSessionKey(params){
	return get(
		'/getSessionKey',
		params,
	)
  },
  // 会议详情/api/pc/instance/hcp/meeting/get
  getMeeting(params,type){
	let headers = {
		"Content-Type": "application/json",
		"session-key":'6377e1601fd8428c99f325a221d72d32'
	};
	return post(
		`/api/pc/instance/${type}/meeting/get/edj`,
		params,
		headers,
	)
  },
  // 列表详情
  getMettingList(params,type){
	let headers = {
		"Content-Type": "application/json",
		"session-key":'084a1f09c93642a8a381cb1e9a3f61ad'
	};
	return post(
		`/api/pc/instance/${type}/meeting/list/edj`,
		params,
		headers,
	)
  },
  // 获取素材列表 /api/pc/instance/third_employee/merckMaterialList
  getMaterial(params,type){
	let headers = {
		"Content-Type": "application/json",
		"session-key":'084a1f09c93642a8a381cb1e9a3f61ad'
	};
	return post(
		`/api/pc/instance/${type}/merckMaterialList/edj`,
		params,
		headers,
	)
  },
  // 会议数量概览 /api/pc/instance/shop_employee/meeting/meetingReview
   getMeetingReview(params,type){
	let headers = {
		"Content-Type": "application/json",
		"session-key":'084a1f09c93642a8a381cb1e9a3f61ad'
	};
	return post(
		`/api/pc/instance/${type}/meeting/meetingReview/edj`,
		params,
		headers,
	)
  },
  // 今日会议列表 /api/pc/instance/meeting/listToday
  getMeetingToday(params){
	let headers = {
		"Content-Type": "application/json",
		"session-key":'084a1f09c93642a8a381cb1e9a3f61ad'
	};
	return post(
		'/api/pc/instance/meeting/listToday/edj',
		params,
		headers,
	)
 },
  
  
}
