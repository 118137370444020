import * as request from '@/utils/request'


function _request(methods,url,...args){
  console.log(url,"0000000000000000")
  return request[methods](url,...args).then(responseData=>{
    const {success,code,message,data} = responseData
    return success ? data : Promise.reject(`${message}`)
  })
}
const get = (...args)=>_request('get',...args)
const post = (...args)=>_request('post',...args)


//首页数据
export const getHomeData=()=>get('/index/indexInfo')

//首页菜单
export const getHomeProducts=()=>get('/index/productList')
