import axios from "axios";
import $storage from "../utils/stroage.js";
import Router from '@/router/index'
import config from "@/config/urlConfig.js";

axios.defaults.withCredentials = true;
console.dir(config)

const service = axios.create({
	baseURL: config.baseUrl,
	timeout: 50000
});
window.axiosCancel = []  // 全局定义一个存放取消请求的标识
//http request 拦截器
service.interceptors.request.use(
	configserve => {
		 /** 添加取消标记 */
		 configserve.cancelToken = new axios.CancelToken(cancel => {
			window.axiosCancel.push({
			cancel
			})
		 })
		console.log(configserve)
		if(configserve.url.indexOf('/edj')!=-1){
			configserve.baseURL = config.mettingUrl;
			configserve.url = configserve.url.substring(configserve.url.indexOf('/edj'),0)
		}
		console.log(configserve,'configserve')
		// if(!$storage.getLocalItem("token")){
		//     if(location.href.indexOf('login') < 0){
		//         Router.replace({name: 'login'})
		//     }
		// }
		return configserve;
	},
	error => {
		if ($storage.getLocalItem("token")) {
			return Promise.reject(err);
		}

	}
);

// service.interceptors.request.use(
//     config => {
//         return config;
//     },
//     error => {
//         console.log(error);
//         return Promise.reject();
//     }
// );

service.interceptors.response.use(
	response => {
		if ($storage.getLocalItem("token")) {
			response.headers = {
				"Content-Type": "application/json",
				Accept: "*/*",
				// Authorization: $storage.getLocalItem("_token")
			};
			// response.headers['Authorization'] = $storage.getLocalItem('token')
		} else {}

		if (response.status === 200) {
			let _this = this;
			// 1、拦截接口403 -- 未登录或者登录过期 2、拦截接口405，未选择标签跳转选择标签页
			if (response && response.data && response.data.code == 403) {
				$storage.setLocalItem('token', "");
				setTimeout(() => {
					if (location.href.indexOf('login') < 0) {
						Router.replace({
							name: 'login'
						})
					}
				}, 1000)
				return response.data;

			} else if (response && response.data && response.data.code == 405) {
				Router.push({
					name: 'registerNext'
				})
			} else {
				return response.data;
			}

		} else {
			Promise.reject();
		}
		return response;
	},
	error => {
		if ($storage.getLocalItem("token")) {
			return Promise.reject();
		}
	}
);

// let token = $storage.getLocalItem('token')
// console.log(token,'token')
/**
 * 封装get方法
 * @param url
 * @param params
 * @returns {Promise}
 */

export function get(url, params = {}, headers = {
	'Authorization': $storage.getLocalItem('token')
}) {
	return new Promise((resolve, reject) => {
		service
			.get(url, {
				params: params,
				headers: headers,
			})
			.then(response => {
				resolve(response);
			})
			.catch(err => {
				reject(err);
			});
	});
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}, headers = {
	'Authorization': $storage.getLocalItem('token'),
}) {
	let header = Object.assign(headers, {
		'Authorization': $storage.getLocalItem('token')
	})
	return new Promise((resolve, reject) => {
		service.post(url, data, {
			headers: header,
		}, ).then(
			response => {
				resolve(response);
			},
			err => {
				reject(err);
			}
		);
	});
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function patch(url, data = {}, headers = {}) {

	return new Promise((resolve, reject) => {
		service.patch(url, data, {
			headers
		}).then(
			response => {
				resolve(response);
			},
			err => {
				reject(err);
			}
		);
	});
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}, headers = {}) {

	return new Promise((resolve, reject) => {
		service.put(url, data, {
			headers
		}).then(
			response => {
				resolve(response);
			},
			err => {
				reject(err);
			}
		);
	});
}

// export default service;
